.pulse-loading-wrapper {
  position: relative;
  z-index: 9999;
  width: 60px;
}

.pulse-loader {
  position: absolute;
  inset: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  background-color: #0000005e;
  outline: 1px solid transparent;
  animation: pulseanim 1s ease-in-out infinite;
  color: #0000005e;
}

@keyframes pulseanim {
  0% {
    transform: scale(0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
