@font-face {
    font-family: 'Akko Pro';
    src: local('Akko Pro'), url("./assets/fonts/AkkoProRegular.otf") format('opentype');
    font-weight: normal;
}

@font-face {
    font-family: 'Akko Pro';
    src: local('Akko Pro'), url("./assets/fonts/AkkoProMedium.otf") format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'Akko Pro';
    src: local('Akko Pro'), url("./assets/fonts/AkkoProBold.otf") format('opentype');
    font-weight: 700;
}