.map {
  width: 100wh;
  height: calc(100vh - 40px);
}

.fa-stack.small {
  font-size: 1em;
}

.fa-stack.large {
  font-size: 1.5em;
}

i {
  text-align: center;
  padding: 0;
}

.reactLeafletSearch-style {
  margin-top: 5px !important;
  margin-left: 4px !important;
  border: none !important;
  box-shadow: none !important;
  --icon-width: 22px !important;
  --icon-height: 22px !important;
  --active-height: 22px !important;
  --close-button-max-size: 12px !important;
  --icon-button-max-size: 18px !important;
  --primary-color: #fff !important;
  --secondary-color: rgba(141, 141, 141, 0.639) !important;
  --border-size: 0px !important;
  --main-background-color: #286795 !important;
  --background-color-candidate: #5a6673 !important;
  --background-color-hover: #5a6673b3 !important;
  --background-color-active: #1890ff !important;
  --svg-stroke-width: 5px !important;
}
.leaflet-search-wrap {
  margin-left: 56px !important;
  margin-top: -61px !important;
}

.leaflet-top {
  top: 126px !important;
  left: 10px !important;
}

.leaflet-control-zoom {
  width: 25px !important;
  height: 58px !important;
}

.leaflet-control-zoom-in {
  width: 21px !important;
  height: 27px !important;
  font-size: 18px !important;
  color: #546063 !important;
}

.leaflet-control-zoom-out {
  width: 21px !important;
  height: 27px !important;
  font-size: 18px !important;
  color: #546063 !important;
}

.leaflet-control-geosearch a.leaflet-bar-part:after {
  top: 5px !important;
  left: 6px !important;
}

.leaflet-bar-part {
  width: 21px !important;
  height: 21px !important;
}

.leaflet-control-geosearch a.leaflet-bar-part:before {
  top: 13px !important;
  left: 11px !important;
}

.geosearch {
  width: 25px;
  height: 25px;
  color: #546063 !important;
}

.search-control-info-list-item {
  font: 16px Roboto, Verdana, sans-serif !important;
}

/* Firefox */
.search-control-input::placeholder {
  color: white !important;
  opacity: 1;
}

/* Internet Explorer 10-11 */
.search-control-input::-ms-input-placeholder {
  color: white;
}

/* Microsoft Edge */
.search-control-input::-ms-input-placeholder {
  color: white;
}

@keyframes changeColor {
  0% {
    fill: rgba(163, 163, 164, 50);
  }
  25% {
    fill: rgba(141, 141, 142, 50);
  }
  50% {
    fill: rgba(123, 123, 124, 50);
  }
  75% {
    fill: rgba(115, 115, 116, 50);
  }
  100% {
    fill: rgba(91, 91, 92, 50);
  }
}

.loading-circle {
  animation: changeColor 1s linear infinite alternate;
}

.fa-beat {
  animation: fa-beat 1s ease infinite alternate;
}

@keyframes fa-beat {
  0% {
    transform: scale(0.65);
  }
  25% {
    transform: scale(0.75);
  }
  50% {
    transform: scale(0.85);
  }
  75% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.leaflet-tooltip {
  padding: 0 !important;
  background-color: rgba(0, 0, 0, 0.33) !important;
  border: none !important;
  border-radius: 4px !important;
  backdrop-filter: blur(4px);
}

.leaflet-tooltip-right:before {
  border-right-color: rgba(0, 0, 0, 0.33) !important;
  backdrop-filter: blur(4px);
}

.leaflet-tooltip-left:before {
  border-left-color: rgba(0, 0, 0, 0.33) !important;
  backdrop-filter: blur(4px);
}
