.inputLogin{
    width: 20% !important;
    display: inline-block !important;
}

@media (min-height: 800px) {
    .inputLogin {
        width: 27% !important;
        display: inline-block !important;
    }
  }