@media (min-height: 800px) {
  .panelButtonEnvironmental{   
      margin-left: 90% !important;  
  }
}

.environmentalButton{
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
}