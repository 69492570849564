#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

html {
  font-size: 16px;
}

.logout {
  margin-top: 27px;
  height: 30px;
  width: 30px;
  float: right;
}
.ant-layout-content {
  min-height: auto !important;
}

.layout-grid {
  display: grid;
  grid-template-areas:
    "side menu menu"
    "side main main"
    "side main main";
  grid-template-rows: 40px auto auto;
  transition: 0.3s all ease-in-out;
  height: 100dvh;
  overflow-x: hidden;
}

.layout-grid-open {
  grid-template-columns: 225px 1fr;
}

.layout-grid-collapsed {
  grid-template-columns: 75px 1fr;
}

.side-area {
  grid-area: side;
  position: relative;
  background: var(--chakra-colors-blue-200);
}

.menu-area {
  grid-area: menu;
}

.main-area {
  grid-area: main;
}
